<template>
  <v-container class="pending_tasks">
    <v-row>
      <v-col cols="5" class="pt-4">
        <ProductTourTooltip :direction="'right'" :show="step == 2">
          <h2 class="subtitle_header" style="display: inline">
            {{ $t('pendingTasks.title') }}
          </h2>
        </ProductTourTooltip>
      </v-col>
      <v-col v-if="allPendingUsers.length > 0" cols="12" class="pt-4 pb-8">
        <data-table
          :headers="pendingHeaders"
          :items="allPendingUsers"
          :tableOptions="true"
          @updateTable="updateTable()"
          @approveAll="approveAll()"
        >
        </data-table>
      </v-col>
      <v-col v-else cols="12" class="pt-4 pb-8">
        <v-card class="pa-8">
          <v-img
            class="mx-auto"
            max-height="89"
            max-width="98"
            :src="emptyTablePhoto"
          />
          <v-card-text class="strong_text pa-0 pt-2">
            <p class="light_text text-center mb-0">
              {{ $t('pendingTasks.emptyTable') }}
            </p>
          </v-card-text></v-card
        >
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import { DASHBOARD } from '../../store/actions';
import DataTable from '../DataTable';

import ProductTourTooltip from '../ProductTourTooltip';

export default {
  name: 'PendingTasks',
  components: {
    ProductTourTooltip,
    DataTable,
  },
  created() {
    this.debug(`*** ${this.$options.name} ***`);
  },
  mounted() {
    this.checkForPublicFields(this.allPendingUsers);
  },
  watch: {
    allPendingUsers(value) {
      this.debug('allPendingUsers', this.allPendingUsers);
      this.checkForPublicFields(value);
    },
  },
  computed: {
    ...mapGetters(['allPendingUsers']),
    ...mapGetters('tour', ['step']),
  },
  methods: {
    checkForPublicFields(value) {
      this.showPublicField = value.some((e) => e.public_field);
      if (!this.showPublicField) {
        for (const key in this.pendingHeaders) {
          if (Object.hasOwnProperty.call(this.pendingHeaders, key)) {
            const element = this.pendingHeaders[key];
            this.pendingHeaders[key] = element.map((e) => {
              if (e.value == 'public_field') {
                return { ...e, align: ' d-none' };
              } else {
                return e;
              }
            });
          }
        }
      }
    },

    updateTable() {
      this.$store.dispatch(DASHBOARD); //.then(() => this.getTemplate());
    },
  },
  data() {
    return {
      userData: {},
      showPublicField: false,
      emptyTablePhoto: require('../../assets/illustration-no-pending-tasks.jpg'),
      pendingHeaders: {
        en: [
          { text: 'Credential template', value: 'cred_name' },
          {
            text: 'Public Attribute',
            align: 'start',
            value: 'public_field',
          },
          { text: 'Status', value: 'status', filterable: false },
          {
            text: 'Actions',
            value: 'actions',
            filterable: false,
            sortable: false,
            width: 50,
          },
        ],
        pt: [
          { text: 'Credential template', value: 'cred_name' },
          {
            text: 'Public Attribute',
            align: 'start',
            value: 'public_field',
          },
          { text: 'Status', value: 'status', filterable: false },
          {
            text: 'Actions',
            value: 'actions',
            filterable: false,
            sortable: false,
            width: 50,
          },
        ],
        es: [
          { text: 'Credential template', value: 'cred_name' },
          {
            text: 'Public Attribute',
            align: 'start',
            value: 'public_field',
          },
          { text: 'Status', value: 'status', filterable: false },
          {
            text: 'Actions',
            value: 'actions',
            filterable: false,
            sortable: false,
            width: 50,
          },
        ],
      },
    };
  },
};
</script>
<style lang="scss">
.container.pending_tasks {
  .v-card {
    box-shadow: 0 2px 4px 0 var(--very-light-grey) !important;
  }
  .v-data-table {
    box-shadow: 0 2px 4px 0 var(--very-light-grey) !important;
    .v-data-table-header {
      .text-start span {
        font-size: 12px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: var(--charcoal-grey);
      }
    }
    th {
      padding: 2px 20px;
    }
  }
  .v-tabs {
    border-bottom: solid 1px var(--light-grey-blue);
    .v-tabs-bar {
      height: 38px;
      background-color: transparent;
      .v-tabs-slider {
        background-color: #0ea4b5;
      }
    }
    .v-tab {
      margin: 0;
      max-width: 160px;
      padding: 0;
    }
  }
  .v-tabs-items {
    background-color: transparent;
  }
}
</style>
