<template>
  <v-container class="dashboard">
    <v-row>
      <!-- <v-col cols="12" class="pt-10 breadcrumbs">
        <p v-show="isOpenCredendial" class="">
          <DashBoardIcon
            :size="14"
            :color="'#b8b9bb'"
            style="cursor: pointer;"
          />
          <IconArrowCreadCrumbs style="margin: 0px 6px 2px 6px" />
          <span style="margin: 2px 6px 2px 2px; position:absolute">
            {{ credential }}</span
          >
        </p> 
      </v-col> -->
      <v-col cols="12" class="pt-15">
        <h1 class="title_header">{{ caName }} {{ $t('dashboard.title') }}</h1>
      </v-col>
      <v-col cols="6" class="py-4">
        <h2 class="subtitle_header">
          {{ $t('dashboard.subtitle') }}
        </h2>
      </v-col>
      <v-col cols="12" md="9" class="pb-8 pr-16">
        <v-container class="pa-0 pt-3">
          <v-row class="pb-5">
            <v-col
              v-for="cred in templates"
              :key="cred.tid"
              cols="4"
              class="pa-2 pr-3"
            >
              <CredentialButton
                @open="openCredential(cred)"
                :name="cred.name"
              />
            </v-col>
            <v-col v-if="isMaster && hasCredentials" cols="4" class="pa-2 pr-3">
              <ProductTourTooltip :direction="'right'" :show="step == 3">
                <CredentialButton
                  :name="$t('dashboard.emptyTemplate')"
                  @open="addNewTemplate"
                  :blank="isMaster"
                />
              </ProductTourTooltip>
            </v-col>
          </v-row>
          <v-row> <PendingTasks /> </v-row>
        </v-container>
      </v-col>
      <v-col cols="12" md="3" class="pt-2 pb-8 counters">
        <v-row class="">
          <v-col cols="12" class="pt-2 pb-2 px-0">
            <v-card>
              <v-card-text class="strong_text">
                <IconTemplatesCredentials />
                <div>
                  <p>{{ totalTemplatesCounter }}/{{ maxTemplatesCounter }}</p>
                  <span> {{ $t('dashboard.counters.templates') }} </span>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" class="pt-4 pb-2 px-0">
            <v-card>
              <v-card-text class="strong_text">
                <IconIssuedCredentials />
                <div>
                  <p>{{ totalIssuedCredentials }}/{{ maxIssuedCounter }}</p>
                  <span> {{ $t('dashboard.counters.issued') }} </span>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" class="pt-2 pb-2 px-0">
            <v-card>
              <v-card-text class="strong_text">
                <IconPendingCredentials />
                <div>
                  <p>{{ allPendingUsers.length }}</p>
                  <span> {{ $t('dashboard.counters.pending') }} </span>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <NewTemplate
      v-if="showNewTemplateModal && !isCustomTemplate"
      @close="openCredential"
      @back="
        (showCreateCAModal = true),
          (startingStep = 1),
          (showNewTemplateModal = false)
      "
    />
    <NewTemplateKeepCoding
      v-if="showNewTemplateModal && isCustomTemplate"
      @close="openCredential"
      @back="
        (showCreateCAModal = true),
          (startingStep = 1),
          (showNewTemplateModal = false)
      "
    />
    <CreateCAModal
      v-if="showCreateCAModal"
      :startingStep="startingStep"
      @close="updateDashboard"
    />
  </v-container>
</template>

<script>
// import { mapGetters } from "vuex";
import PendingTasks from '../components/PendingTasks';
import CredentialButton from '../components/CredentialButton';

import IconIssuedCredentials from '../assets/icon-issued-credentials.vue';
import IconPendingCredentials from '../assets/icon-pending-credentials.vue';
import IconTemplatesCredentials from '../assets/icon-templates-created';

import NewTemplate from '../modals/NewTemplate';
import NewTemplateKeepCoding from '../modals/NewTemplateKeepCodingV1';

import CreateCAModal from '../modals/CreateCA';

import { mapGetters } from 'vuex';
import { WALLID_ADDRESS } from '../store/modules/plugin';
import { DASHBOARD, PROFILE } from '../store/actions';

import ProductTourTooltip from '../components/ProductTourTooltip';

export default {
  name: 'DASHBOARD',
  components: {
    PendingTasks,
    CredentialButton,
    NewTemplate,
    NewTemplateKeepCoding,
    CreateCAModal,
    IconTemplatesCredentials,
    IconIssuedCredentials,
    IconPendingCredentials,
    ProductTourTooltip,
  },
  created() {
    this.debug(`*** ${this.$options.name} ***`);

    this.debug('cid created value: ', this.cid);
    this.showCreateCAModal = this.cid == '' ? true : false;
  },
  watch: {
    cid(value) {
      if (!this.showCreateCAModal) {
        this.debug('cid watch value: ', value);
        this.showCreateCAModal = value == '';
      }
    },
  },
  mounted() {},

  computed: {
    ...mapGetters([
      'adminEmail',
      'isMaster',
      'caName',
      'templates',
      'maxTemplatesCounter',
      'maxIssuedCounter',
      'allPendingUsers',
      'totalIssuedCredentials',
    ]),

    ...mapGetters('tour', ['step']),
    cid() {
      // gets updated automatically
      return this.$store.state.cid;
    },
    totalTemplatesCounter() {
      return this.$store.state.templates.length;
    },
    templates() {
      // gets updated automatically
      return this.$store.state.templates;
    },
    hasCredentials() {
      return (
        !this.$isProduction || this.templates.length < this.maxTemplatesCounter
      ); // return true for testing
    },
    isCustomTemplate() {
      return (
        this.adminEmail &&
        this.customTemplate[this.getEmail(this.adminEmail).domain]
      );
    },
  },
  methods: {
    updateDashboard() {
      this.showCreateCAModal = false;
      this.$store
        .dispatch(WALLID_ADDRESS)
        .then(() => this.$store.dispatch(PROFILE))
        .then(() => this.$store.dispatch(DASHBOARD))
        .then(() => this.$store.commit('isLoading', false))
        .catch((err) => {
          this.$router.push({
            path: '/SignIn',
            query: { redirect: this.$route.path },
          });
          console.error(err.message);
          // localStorage.removeItem
        });
    },
    addNewTemplate() {
      // this.showNewTemplateModal = true;
      this.$router.push({
        path: `CredentialDesign`,
      });
    },

    /**
     * pushes to router ViewTemplate route with the @template tid
     */
    openCredential(template) {
      this.showNewTemplateModal = false;
      this.debug('template tid: ', template.tid);
      if (template.tid) {
        this.$router.push({
          path: `Dashboard/ViewTemplate/${template.tid}/`,
        });
      }
    },
  },
  data() {
    return {
      customTemplate: { 'keepcoding.io': true },
      pendingCounter: 0,
      totalTemplates: 0,
      startingStep: 0,
      showCreateCAModal: false,
      showNewTemplateModal: false,
    };
  },
};
</script>

<style lang="scss"></style>
